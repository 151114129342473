<template>
  <v-row justify="center">
    <v-dialog v-model="shower" width="800px" scrollable persistent>
      <v-card>
        <v-card-title>{{ data.policy.policy_header }}</v-card-title>          
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <div v-html='data.policy.policy_detail'></div>
          <v-layout justify-center align-center>
            <v-card-subtitle>{{ $t("myinboxPage.openConsent") }} {{ data.consent_system }}</v-card-subtitle>
            <v-list-item-action class="darkmode">
              <!-- <v-switch
                inset
                :color="color.theme"
                v-model="data.status"
              ></v-switch> -->
              <v-btn-toggle
                v-model="data.status"
                rounded
                dense 
                :mandatory="data.status !== 'wait'"
                :color="data.status === 'unapprove' ? 'red' : 'green'"
              >
                <v-btn value="unapprove">{{ $t("myinboxPage.reject") }}</v-btn>
                <v-btn value="approve">{{ $t("myinboxPage.accept") }}</v-btn>
              </v-btn-toggle>
            </v-list-item-action>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close')">{{ $t("admin.close") }}</v-btn>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="submitConsent()"
          >
            {{ $t("myinboxPage.settingHeader.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "datapolicy"],
  data: function () {
    return {
      data: {
        consent: {
          consent_detail: "",
          consent_header: ""
        },
        consent_system: "",
        policy: {
          policy_detail: "",
          policy_header: ""
        },
        status: "",
        system_id: ""
      },
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          Object.assign(this.data, this.datapolicy)
          // this.data.status = this.datapolicy.status === "approve"
        }
        return this.show
      },
    }
  },
  methods: {
    async submitConsent() {
      var payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        system_consent: this.data.system_id,
        // consent_status: this.data.status ? "approve" : "unapprove",
        consent_status: this.data.status,
        one_biz_id: this.dataAccountActive.business_info.id,
      }
      console.log('payload', payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/set_accept_system", payload, {
          headers: { Authorization: auth.code },
        })
        .then(response => {
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: this.$t("toast.consentSuccess")
            });
            this.$emit('submit');
            this.$emit('close');
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            this.$emit('close');
          }
        }).catch(error => {
          console.log("error", error);  
          Toast.fire({
            icon: "error",
            title: error
          });
        })
      
    },
  },
};
</script>
<style></style>
